@mixin paragraph-spacing {
    @apply mb-24;

    &:last-child {
        @apply mb-0;
    }
}

.richtext {
    // Block Styles

    h1 {
        @extend .headline-1;
    }

    h2 {
        @extend .headline-2;
    }

    h3 {
        @extend .headline-3;
    }

    h4,
    h5,
    h6 {
        @extend .headline-4;
        @apply text-black-40;
    }

    p {
        @extend .paragraph;
        @include paragraph-spacing;
    }

    ol {
        @extend .list;
        @include paragraph-spacing;
        @apply list-decimal;
    }

    ul {
        @extend .list;
        @include paragraph-spacing;
        @apply list-disc;

        > li::marker {
            font-size: 0.8rem;
        }
    }

    // Custom Block Styles

    p.ezstyle-small {
        @apply text-14;
        @apply xl:text-16;
    }

    // Inline Styles

    a:not(.group\/textlink, .group\/button) {
        @apply underline underline-offset-4 transition-colors hocus:text-red-100;
    }

    .group\/textlink {
        vertical-align: middle;
    }

    .group\/button {
        margin: 5px 0;
        vertical-align: middle;
    }

    // Custom Inline Styles

    .ezstyle-grey {
        @apply text-black-40;
    }

    .ezstyle-serif {
        @apply font-serif;
    }

    .table-responsive {
        @apply overflow-x-auto;
        @include paragraph-spacing;
    }

    @mixin table-default {
        @apply w-full;

        tr:hover {
            @apply cursor-default bg-anthracite-30;
        }

        td,
        th {
            @apply min-w-96 px-12 py-6 text-left font-350;
        }

        thead {
            @apply bg-anthracite-10;
        }

        tbody {
            tr {
                @apply border-b-1 border-anthracite-30;
            }

            th {
                @apply bg-anthracite-10;
            }
        }
    }

    .table--default {
        @include table-default;
    }

    .table--25 {
        @include table-default;

        td {
            @apply w-1/4 min-w-144;
        }
    }

    .table--33 {
        @include table-default;

        td {
            @apply w-1/3 min-w-144;
        }
    }

    .table--50 {
        @include table-default;

        td {
            @apply w-1/2 min-w-144;
        }
    }
}

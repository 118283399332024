.uc-embedding-container {
    @apply bg-anthracite-10;
}

.uc-embedding-wrapper {
    @apply rounded-none;

    button {
        @apply rounded-none;
    }
}

.uc-embedding-accept {
    @apply bg-red-100;
}

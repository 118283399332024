@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @import "./frontend/_scss/icons";
    @import "./frontend/_scss/text-styles";
    @import "./frontend/_scss/sections";
}

@import "./frontend/_scss/_richtext";

@layer utilities {
    .scrollbar-invisible {
        &::-webkit-scrollbar {
            opacity: 0;
        }

        // And for Firefox... :o
        scrollbar-color: transparent transparent;

        // But reset for nested elements... :(
        & > * {
            scrollbar-color: initial;
        }
    }
}

@import "frontend/_scss/usercentrics";
